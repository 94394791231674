.rectangle {
  position: relative;
  z-index: 2;
  width: 60%;
  padding: 30px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85%;
}


.logo {
  width: 250px;
  margin-bottom: 20px;
}


.home-button {
  position: absolute;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1001;
  left: 20px; /* Ajusta este valor para mover el botón hacia la izquierda dentro del rectángulo */
  top: 20px; /* Ajusta este valor para mover el botón hacia abajo dentro del rectángulo */

}


.home-button img {
  width: 40px; /* Ocupa todo el tamaño del botón */
  height: 40px; /* Ocupa todo el tamaño del botón */
}


.inner-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.inner-rectangle-left, .inner-rectangle-right {
  top: 20%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner-rectangle-left {
  width: 55%;
}

.inner-rectangle-right {
  width: 40%;
}

.left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding-top: 20px;
}

.right-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px;

}

.avatars-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}

.vs-text {
  margin: 0 20px;
  font-size: 2em;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.avatarvs {
  background-size: cover;
  background-position: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-bottom: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.nickname {
  text-align: center;
}

.nickname p {
  margin: 0;
  font-weight: bold;
  color: #ffffff;
  font-size: 1.4em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Room ID */
.room-id-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
}

/* Ready Button */
.ready-button {
  width: 25%;
  padding: 12px 24px;
  border-radius: 15px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #dc3545; /* Red initial */
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.ready-button.ready {
  background-color: #28a745; /* Green when ready */
}

.ready-button:hover:not(:disabled) {
  background-color: #218838;
  transform: scale(1.05);
}

.ready-button:active {
  transform: scale(0.95);
}

/* Botón de la casa para volver al lobby */


/* Modal de confirmación */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 80%;
}

.modal h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.modal p {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirmation-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-buttons button:first-child {
  background-color: #f44336; /* Color rojo para confirmar */
  color: white;
}

.confirmation-buttons button:last-child {
  background-color: #4CAF50; /* Color verde para cancelar */
  color: white;
}
