@keyframes rotate-background {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .refresh-icon {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
  }
  
  .refresh-icon:hover {
    transform: rotate(90deg);
  }
  