.countdown-3d-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .container {
    width: 648px;
    height: 540px;
  }