.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fondo más oscuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(10px); /* Efecto de desenfoque */
  }
  
  .modal-content {
    background: linear-gradient(145deg, #1e1e1e, #292929); /* Degradado oscuro */
    padding: 30px;
    border-radius: 12px;
    width: 350px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    text-align: center;
    color: #fff; /* Texto blanco */
    border: 1px solid #3a3a3a; /* Bordes gris oscuro */
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    font-size: 1.8em;
    color: #ffd700; /* Color dorado para el título */
    text-shadow: 0 0 5px rgba(255, 215, 0, 0.8); /* Efecto de brillo en el título */
  }
  
  .modal-content input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ffd700; /* Bordes dorados */
    border-radius: 8px;
    background-color: #333; /* Fondo oscuro */
    color: #fff;
    font-size: 1.1em;
    text-align: center;
    outline: none;
  }
  
  .modal-content input:focus {
    border-color: #ff4500; /* Color de foco anaranjado */
    box-shadow: 0 0 8px #ff4500; /* Efecto de brillo en el foco */
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 15px; 
  }
  
  .btn-join {
    background: linear-gradient(145deg, #ff4500, #ff8c00); /* Degradado anaranjado */
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1em;
    box-shadow: 0 0 8px rgba(255, 140, 0, 0.7); /* Efecto de brillo en el botón */
    transition: all 0.3s ease;
    flex: 1; /* Asegura que ambos botones ocupen el mismo espacio */

  }
  
  .btn-join:hover {
    background: linear-gradient(145deg, #ff8c00, #ffa500); /* Cambio de color al pasar el mouse */
    box-shadow: 0 0 12px rgba(255, 140, 0, 1);
  }
  
  .btn-cancel {
    background: linear-gradient(145deg, #ff1a1a, #ff4c4c); /* Degradado rojo */
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1em;
    box-shadow: 0 0 8px rgba(255, 26, 26, 0.7); /* Efecto de brillo en el botón */
    transition: all 0.3s ease;
    flex: 1; /* Asegura que ambos botones ocupen el mismo espacio */

  }
  
  .btn-cancel:hover {
    background: linear-gradient(145deg, #ff4c4c, #ff6666); /* Cambio de color al pasar el mouse */
    box-shadow: 0 0 12px rgba(255, 26, 26, 1);
  }
  