/* Para la vista de Ready, avatares grandes */
.avatar-ready {
  width: 160px;
  height: 160px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

/* Para la vista de Game, avatares pequeños */
.avatar-game {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
/* Contenedor principal del área de juego */
.game-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  box-sizing: border-box;
}

/* Estilos para la entrada de número y el botón */
.number-input,
.game-started {
  margin: 20px 0; /* Añade espacio alrededor de los elementos */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos dentro del contenedor */
}

/* Estilos para el campo de entrada */
.number-input input,
.game-started input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #4B0082;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  text-align: center; /* Centra el texto dentro del input */
}

/* Estilos para los botones */
.number-input button,
.game-started button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4B0082;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Espacio encima del botón */
}

.number-input button:hover,
.game-started button:hover {
  background-color: #7F2A83;
}

/* Ajustes generales */
.game-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Asegurado para alinear al inicio */
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.left-section {
  display: flex;
  flex-direction: column; /* Coloca los elementos uno debajo del otro */
  align-items: center;
  justify-content: center;
  width: 50%; /* Ajusta este ancho según sea necesario */
}

.right-section {
  width: 30%;
  height: 100%; /* Asegura que el historial ocupe toda la altura disponible */
}

/* VS Container */
.vs-container {
  display: flex;
  flex-direction: column; /* Cambiado a columna para colocar elementos verticalmente */
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px; /* Espacio adicional entre el VS y el cuadro de número */
}

.guess-history {
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  height: 460px;
  overflow-y: auto;
  border: 2px solid rgba(150, 100, 255, 0.8);
  transform: translateY(-150px); /* Ajusta este valor según lo necesites */
}

.history-list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #ffffff;
  font-family: 'Orbitron', sans-serif;
}

.history-list li {
  padding: 10px 0;
  border-bottom: 1px solid rgba(150, 100, 255, 0.4);
}

.history-list li:last-child {
  border-bottom: none;
}

h2 {
  color: #4B0082;
  font-family: 'Orbitron', sans-serif;
  text-shadow: 0 0 5px rgba(150, 100, 255, 0.6);
}

/* Icono VS para la vista de Ready, tamaño grande */
.vs-icon-ready,
.vs-icon-game {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Icono VS para la vista de Ready, tamaño grande */
.vs-icon-ready img {
  width: 80px;
  padding: 20px;
  height: auto;
  object-fit: contain;
}

/* Icono VS para la vista de Game, tamaño pequeño */
.vs-icon-game img {
  width: 60px;
  height: auto;
  object-fit: contain;
}

.number-input,
.game-started {
  margin: 20px 5; /* Añade espacio alrededor de los elementos */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos dentro del contenedor */
  justify-content: center; /* Asegura que el contenido esté centrado verticalmente si hay suficiente espacio */
}

/* Asegúrate de que los campos de entrada sean visibles */
.number-input input,
.game-started input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #4B0082;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  text-align: center; /* Centra el texto dentro del input */
}

/* Contenedor del input y el botón */
.number-input-container,
.guess-input-container {
  display: flex;
  flex-direction: column; /* Coloca los elementos uno debajo del otro */
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: center; /* Centra verticalmente si hay suficiente espacio */
  width: 50%; /* Asegúrate de que ocupe todo el ancho disponible */
  box-sizing: border-box; /* Incluye el padding y el border en el ancho y alto total */
}

/* Estilos para el campo de entrada */
.number-input,
.guess-input {
  width: 20%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #4B0082;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  text-align: center; /* Centra el texto dentro del input */
}

/* Estilos para el botón de enviar número */
.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4B0082;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Espacio encima del botón */
  text-align: center; /* Asegura que el texto dentro del botón esté centrado */
}

.submit-button:hover {
  background-color: #7F2A83;
}



