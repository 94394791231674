.balloon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .balloon {
    width: 50px;
    height: 70px;
    border-radius: 50%;
    position: absolute;
    animation: float 4s ease-in-out infinite;
  }
  
  .red {
    background-color: #ff4c4c;
    left: 20%;
    animation-delay: 0s;
  }
  
  .blue {
    background-color: #4c6eff;
    left: 40%;
    animation-delay: 1s;
  }
  
  .green {
    background-color: #4cff88;
    left: 60%;
    animation-delay: 2s;
  }
  
  .yellow {
    background-color: #ffe04c;
    left: 80%;
    animation-delay: 3s;
  }
  
  @keyframes float {
    0% {
      transform: translateY(100%);
    }
    50% {
      transform: translateY(-10%) translateX(10px);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  