.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 80%;
  }
  
  .modal h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  .modal p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
  

 
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 80%;
  }
  
  .modal h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  .modal p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
  
  .confirmation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .confirmation-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirmation-buttons button:first-child {
    background-color: #f44336; /* Color rojo para confirmar */
    color: white;
  }
  
  .confirmation-buttons button:last-child {
    background-color: #4CAF50; /* Color verde para cancelar */
    color: white;
  }
 
