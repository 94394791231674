button {
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
}

button:disabled {
  background-color: rgba(0, 123, 255, 0.5);
  cursor: not-allowed;
  box-shadow: none;
}

button:not(:disabled):hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

button .icon {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.ready-buttons, .ready-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.ready-button {
  width: 60%;
  padding: 12px 24px;
  border-radius: 15px;
  font-size: 1.2em;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.ready-button:disabled {
  background-color: #ccc;
}

.ready-button.ready {
  background-color: #28a745;
}

.ready-button.ready:hover:not(:disabled) {
  background-color: #218838;
  transform: scale(1.05);
}

.ready-button.not-ready {
  background-color: #dc3545;
}

.ready-button.not-ready:hover:not(:disabled) {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Estilo del botón de registrar */
button.register-button {
  border: none;
  border-radius: 10px;
  background: linear-gradient(135deg, #ffffffa8, #cccccc);
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  padding: 12px 24px;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15), inset 0 1px 3px rgba(255, 255, 255, 0.3);
  width: 200px;
  position: relative;
  z-index: 1;
}

button.register-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(135deg, #ffffffc2, #cccccc);
  filter: blur(6px);
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: 0.5;
}

button.register-button:hover::before {
  opacity: 0.7;
}

button.register-button:disabled {
  background-color: rgba(200, 200, 200, 0.5);
  cursor: not-allowed;
  box-shadow: none;
}

button.register-button:hover:not(:disabled) {
  transform: translateY(-2px) scale(1.03);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3), inset 0 1px 3px rgba(255, 255, 255, 0.5);
}

button.register-button:active {
  transform: translateY(1px) scale(0.98);
}

button.register-button .icon {
  margin-right: 8px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-column button {
  border: none;
  border-radius: 20px;
  background: linear-gradient(145deg, #6441A5, #2a0845);
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.1);
}

.right-column button .icon {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}

.right-column button:hover {
  background: linear-gradient(145deg, #7d56c2, #3b1f69);
  transform: translateY(-2px) scale(1.02);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3), -4px -4px 12px rgba(255, 255, 255, 0.2);
}

.right-column button:active {
  transform: translateY(1px) scale(0.98);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -2px -2px 5px rgba(255, 255, 255, 0.1);
}

.right-column button:disabled {
  background-color: #9a9db5;
  cursor: not-allowed;
  box-shadow: none;
}
