body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Pacifico', cursive;
  color: #ffffff;
  overflow: hidden;
  background: linear-gradient(135deg, #3f51b5 0%, #9c27b0 100%);
  position: relative;
}

.background {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.background::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.05) 10%, transparent 70%),
              repeating-radial-gradient(circle at center, transparent 0%, transparent 10%, rgba(255, 255, 255, 0.03) 10%, rgba(255, 255, 255, 0.03) 20%);
  background-size: cover;
  z-index: 0;
}
