.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

input {
  padding: 12px;
  border: none;
  border-radius: 10px;
  font-size: 1.1em;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  margin-bottom: 20px;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

input::placeholder {
  color: #e0e0e0;
}

input:focus {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* Estilo del campo de entrada de nickname */
input.nickname-input {
  padding: 10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  font-size: 1em;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffffd3;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 0 8px rgba(255, 255, 255, 0.5);
  width: 50%;
  margin-bottom: 20px;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

input.nickname-input::placeholder {
  color: #e0e0e0;
}

input.nickname-input:focus {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 0 10px rgba(255, 255, 255, 0.6);
}
