/* avatars.css */

.avatar {
    background-size: cover;
    background-position: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    margin-bottom: 15px;
    position: relative;
  }
  
  .avatarvs {
    background-size: cover;
    background-position: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #fff;
    margin-bottom: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .refresh-icon {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
  }
  
  .refresh-icon:hover {
    transform: rotate(90deg);
  }
  
  .player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .nickname p {
    margin: 0;
    font-weight: bold;
    color: #ffffff;
    font-size: 1.4em;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  