/* Colores fijos */
html {
    background: #1B2335;
  }
  
  .container {
    width: 648px;
    height: 540px;
    margin: 50px auto;
    padding: 0px;
    background: radial-gradient(circle, #2b344d00 20%, #28314900 5%, #1b233500 75%);
  }
  
  .square {
    width: 50px;
    height: 50px;
    border: 1px solid #ffffff00;
    float: left;
    text-align: center;
    color: #1B2335;
    line-height: 50px;
    padding: 1px;
    perspective: 800px;
  }
  
  .sqrInside {
    width: 45px;
    height: 45px;
    transition: all 1sec ease;
    background: transparent;
  }
  
  .sqrRotate {
    background: #EF7B94;
    transform-style: preserve-3d;
    transition: transform 1s;
    transform: rotateY(180deg);
  }
  
  .sqrYellowToOrange {
    background: linear-gradient(#FFF0BE 90%, #EF7B94 10%);
    animation: neon1 1.5s ease-in-out infinite alternate;
  }
  
  @keyframes neon1 {
    from {
      box-shadow: 0 0 5px #FFF0BE, 0 0 10px #FFF0BE, 0 0 20px #EF7B94;
    }
    to {
      box-shadow: 0 0 20px #FFF0BE, 0 0 30px #FFF0BE, 0 0 40px #EF7B94;
    }
  }
  